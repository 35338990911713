import AppProviders from "./AppProviders";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ClaimsPage from "./pages/ClaimsPage";
import { NotFound } from "./pages/NotFound";
import { LandingPage } from "./pages/LandingPage";
import { FAQPage } from "./pages/FAQ";

export default function App() {
	return (
		<AppProviders>
			<Router basename={process.env.PUBLIC_URL}>
				<Routes>
					<Route key="default" path="/" element={<LandingPage />} />
					<Route key="claim" path="/claim" element={<LandingPage />} />
					<Route key="faq" path="/faq" element={<FAQPage />} />
					<Route
						key="claim"
						path={`/claim/:claimToken/:pinCode`}
						element={<ClaimsPage />}
					/>
					<Route
						key="claim"
						path={`/claim/:claimToken`}
						element={<ClaimsPage />}
					/>

					<Route path="*" element={<NotFound />} />
				</Routes>
			</Router>
		</AppProviders>
	);
}
