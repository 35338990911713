import { LoadingButton } from "@mui/lab";

export interface ButtonProps {
	text: string;
	variant: "contained" | "outlined";
	loading?: boolean;
	startIcon?: any;
	endIcon?: any;
	disabled?: any;
	onClick?: any;
	sx?: any;
}

export default function Button({
	text,
	variant,
	loading,
	startIcon,
	endIcon,
	disabled,
	onClick,
	sx,
}: ButtonProps) {
	return (
		<LoadingButton
			variant={variant}
			sx={{ py: 2, px: 4, borderRadius: "100px", ...sx }}
			startIcon={startIcon}
			endIcon={endIcon}
			disabled={disabled}
			loading={loading}
			onClick={onClick}
		>
			{text}
		</LoadingButton>
	);
}
