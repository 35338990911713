import { BigNumber } from "ethers";

export const truncateString = (
	fullStr: string,
	strLen: number,
	separator: string
) => {
	if (fullStr.length <= strLen) return fullStr;

	separator = separator || "...";

	var sepLen = separator.length,
		charsToShow = strLen - sepLen,
		frontChars = Math.ceil(charsToShow / 2),
		backChars = Math.floor(charsToShow / 2);

	return (
		fullStr.substr(0, frontChars) +
		separator +
		fullStr.substr(fullStr.length - backChars)
	);
};

// add 20%
export function addGasMargin(value: BigNumber): BigNumber {
	return value.mul(BigNumber.from(10000 + 2000)).div(BigNumber.from(10000));
}