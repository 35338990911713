import { Box } from "@mui/material";
import { useEffect } from "react";

interface PageProps {
	title?: string;
	children: any;
	background?: string;
}

const Page = ({ title, children, background }: PageProps) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box component="div" sx={{ p: 4, backgroundColor: background ?? null }}>
			{children}
		</Box>
	);
};

export default Page;
