import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import theme from "../theme";

export interface ItemClaimFlowTitleProps {
	text: string;
}

/**
 * Encapsulates styling associated with a title in the claim flow process.
 */
export default function ItemClaimFlowTitle({ text }: ItemClaimFlowTitleProps) {
	return (
		<Box
			display="flex"
			alignItems="center"
			sx={{ color: theme.palette.primary.dark }}
		>
			<Typography variant="h5" align="left" component="div">
				{text}
			</Typography>
		</Box>
	);
}
