import Typography from "@mui/material/Typography";

export interface ItemClaimFlowSubTitleParagraphProps {
	text: string;
	bold?: boolean;
	margin?: string;
	align?: "center" | "inherit" | "justify" | "left" | "right";
}

export default function ItemClaimFlowSubTitleParagraph({
	text,
	bold,
	margin,
	align,
}: ItemClaimFlowSubTitleParagraphProps) {
	return (
		<>
			{/* Using typography because this deviates too much from the standard Text. */}
			<Typography
				variant="inherit"
				component="div"
				align={align ?? "left"}
				sx={{
					display: "flex",
					alignItems: "center",
					fontSize: "14px",
					lineHeight: 1.5,
					margin: margin ?? "0 0 10px 0",
					fontWeight: bold ? "bold" : "normal",
				}}
			>
				{text}
			</Typography>
		</>
	);
}
