import { useMemo } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

const EthAddressExplorer = ({
	address = "",
	networkId = 1,
	iconColor = "black",
	full = false,
	charLengthOnEitherSide = 4,
	...rest
}) => {
	const displayedAddress = useMemo(() => {
		if (full || !address) return address;
		return `${address.slice(0, 2 + charLengthOnEitherSide)}...${address.slice(
			0 - charLengthOnEitherSide
		)}`;
	}, [address, full, charLengthOnEitherSide]);

	const supportedNetworks = [1, 4, 137, 80001];

	const openExplorer = () => {
		let url = "";
		switch (networkId) {
			default:
				url = `https://etherscan.io/address/${address}`;
				break;
			case 4:
				url = `https://rinkeby.etherscan.io/address/${address}`;
				break;
			case 137:
				url = `https://polygonscan.com/address/${address}`;
				break;
			case 80001:
				url = `https://mumbai.polygonscan.com/address/${address}`;
				break;
		}
		if (url) {
			window.open(url);
		}
	};

	return (
		<Box
			display="flex"
			alignItems="center"
			sx={{ cursor: "pointer" }}
			onClick={openExplorer}
		>
			{displayedAddress}
			{supportedNetworks.includes(networkId) && (
				<Tooltip title={"View in Explorer"}>
					<ExitToAppOutlinedIcon fontSize="small" />
				</Tooltip>
			)}
		</Box>
	);
};

export default EthAddressExplorer;
