// @ts-nocheck

import { useQuery, useMutation } from "react-query";
import { useMemo } from "react";
import { UseItemClaimResult } from "utils/types";
import apiInstance from "./api";
/**
 * Gets the state associated with a specific claim.
 */
export const fetchItemClaim = async (claimToken: string) => {
	const { data } = await apiInstance.get(`v2/public/email-claims/${claimToken}`);
	return data;
};

/**
 * This sends a pin code to the email registered on file for this claim.
 */
const verifyClaim = async (key: string) => {
	const { data } = await apiInstance.post(`v2/public/email-claims/${key}/verify`);
	return data;
};

/**
 * This sends the collectible to the specified address, if the pin is valid.
 */
const redeemClaim = async ({
	key,
	pin,
	address,
}: {
	key: string;
	pin: string;
	address: string;
}) => {
	const payload = {
		address,
		pin,
	};

	const { data } = await apiInstance.post(`v2/public/email-claims/${key}/redeem`, payload);

	return data;
};

/**
 * Retrieves an item claim by its token and exposes other item mutations.
 */
export const useItemClaim = (claimToken: string): UseItemClaimResult => {
	const {
		data: itemClaim,
		status,
		error,
	} = useQuery(["itemClaim", claimToken], () => fetchItemClaim(claimToken), {
		enabled: Boolean(claimToken),
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	// Verifies the ownership of an item by sending a PIN code to the email that has claim on the item.
	const {
		status: verifyItemClaimStatus,
		mutate: verifyItemClaim,
		error: verifyItemClaimStatusError,
	} = useMutation((claimToken: string) => verifyClaim(claimToken), {
		onSuccess: () => {},
		onError: (error) => {
			console.error(error);
		}
	});

	// Send the collectible to the specified address, if the pin is valid.
	const {
		status: redeemItemClaimStatus,
		mutateAsync: redeemItemClaim,
		error: redeemItemClaimError,
	} = useMutation(
		(redeemItemParameters: any) => redeemClaim({ ...redeemItemParameters }),
		{
			onSuccess: () => {},
			onError: (error) => {
				console.error(error);
			}
		}
	);

	if (itemClaim) itemClaim.token = claimToken;

	return {
		itemClaim,
		status,
		statusErrorMessage: error?.response?.data?.error?.detail,

		verifyItemClaim,
		verifyItemClaimStatus,
		verifyItemClaimStatusErrorMessage:
			verifyItemClaimStatusError?.response?.data?.error?.detail,

		redeemItemClaim,
		redeemItemClaimStatus,
		redeemItemClaimStatusErrorMessage:
			redeemItemClaimError?.response?.data?.error?.detail,
	};
};

export const useItemClaimFlowSteps = (): Array<{
	label: string;
	description: string;
}> => {
	return useMemo(
		() => [
			{
				label: "Create or connect your MetaMask wallet",
				description:
					"Connect your MetaMask wallet or create a new one. This will be the address that the NFT is sent to after a successful claim.",
			},
			{
				label: "Send security code",
				description: `Generate a new unique security code to your email address. This security code will verify that you own the email address on our records.`,
			},
			{
				label: "Claim your NFT",
				description: `In a short while, the NFT will be transferred to your MetaMask wallet. You should be able to see it in your favorite marketplaces.`,
			},
		],
		[]
	);
};
