import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import ItemClaimFlowTitle from "./../Title";
import ItemClaimFlowTitleParagraph from "./../TitleParagraph";
import ItemClaimFlowSubTitleParagraph from "./../SubTitleParagraph";
import ItemClaimFlowSubTitle from "./../SubTitle";
import { useEffect, useState } from "react";
import { UseMutateAsyncFunction, UseMutateFunction } from "react-query/types/react";
import { CLAIMFLOW_STEP, NETWORK, QUERY_STATUS } from "../../utils/constants";
import { useWallet } from "../../services/wallet";
import { ItemClaim, redeemItemClaimDataOutput, UseItemClaimResult, voucher } from "../../utils/types";
import Button from "../Button";
import { Link } from "@mui/material";
import MintVoucher from "../MintVoucher";

const Step3LeftHeader = () => {
	return (
		<Box component="div" display="flex" flexDirection="column" sx={{ mb: 1 }}>
			<ItemClaimFlowTitle text={CLAIMFLOW_STEP.VERIFY_IDENTITY_AND_CLAIM} />

			<ItemClaimFlowTitleParagraph text="Follow the steps on this page to claim your NFT." />
		</Box>
	);
};

interface Step3LeftFooterProps {
	pinFromUrl: string | null | undefined;
	itemClaim: ItemClaim;
	verifyItemClaim: UseMutateFunction<any, any, any, any>;
	verifyItemClaimStatus: string;
	verifyItemClaimStatusErrorMessage: string;
	redeemItemClaim: UseMutateAsyncFunction<redeemItemClaimDataOutput, any, any, any>;
	redeemItemClaimStatus: string;
	redeemItemClaimStatusErrorMessage: string;
	advanceToPreviousStep: () => void;
	advanceToNextStep: () => void;
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const Step3LeftFooter = ({
	pinFromUrl,
	itemClaim,
	verifyItemClaim,
	verifyItemClaimStatus,
	verifyItemClaimStatusErrorMessage,
	redeemItemClaim,
	redeemItemClaimStatus,
	redeemItemClaimStatusErrorMessage,
	advanceToPreviousStep,
	advanceToNextStep,
	activeStep,
	setActiveStep,
}: Step3LeftFooterProps) => {
	const [pinCode, setPincode] = useState<string>(pinFromUrl ?? "");
	const { address, chain } = useWallet();
	const itemNetworkId = itemClaim.item.token_contract.network_id;
	const differentNetwork =
		chain?.id !== itemClaim.item.token_contract.network_id;

	const [voucherData, setVoucherData] = useState<voucher>(itemClaim.voucher);

	if (!address || !itemClaim?.item) {
		advanceToPreviousStep();
	}

	const onSendSecurityCode = () => {
		if (!itemClaim?.token) return;
		setActiveStep(activeStep + 1);
		verifyItemClaim(itemClaim.token);
	};

	const onClaimNFT = async () => {
		if (!itemClaim?.token || !pinCode || !address) return;
		const { voucher } = await redeemItemClaim({
			key: itemClaim.token,
			pin: pinCode,
			address: address,
		});

		if (!voucher) {
			advanceToNextStep();
		} else {
			setVoucherData(voucher);
		}

	};




	return (
		<Box
			component="div"
			display="flex"
			flexDirection="column"
			sx={{ width: { xs: "100%", lg: "475px" }, mt: 5 }}
		>
			{/*  Send security code. */}
			<Box component="div" mb={3} sx={{ opacity: voucherData ? 0.3 : 1 }}>
				<Box component="div" display="flex" flexDirection="column" mb={1}>
					<ItemClaimFlowSubTitle
						text="1. Receive your security code"
						margin="0 0 15px 0"
					/>

					<ItemClaimFlowSubTitleParagraph text="Use the button below to send a unique security code to the email address that has claim on the NFT." />
				</Box>

				<Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
					<Box component="div" sx={{ width: "250px" }}>
						<Button
							variant="contained"
							loading={verifyItemClaimStatus === QUERY_STATUS.loading}
							onClick={onSendSecurityCode}
							text="Send Security Code"
							disabled={voucherData}
							sx={{ width: "225px" }}
						/>
					</Box>

					{(verifyItemClaimStatus === QUERY_STATUS.error ||
						verifyItemClaimStatus === QUERY_STATUS.success) && (
							<Alert
								icon={false}
								sx={{ mt: 2, width: { xs: "100%" } }}
								severity={
									verifyItemClaimStatus === QUERY_STATUS.error
										? "error"
										: "success"
								}
							>
								<ItemClaimFlowSubTitleParagraph
									text={
										verifyItemClaimStatus === QUERY_STATUS.success
											? "Successfully sent a security code to the email address that has claim on the NFT."
											: "Failed to send a security code to the email address that has claim on the NFT."
									}
								/>

								{verifyItemClaimStatusErrorMessage && (
									<ItemClaimFlowSubTitleParagraph
										text={verifyItemClaimStatusErrorMessage}
									/>
								)}
							</Alert>
						)}
				</Box>
			</Box>

			{/*  Enter security code. */}
			<Box component="div" display="flex" flexDirection="column" mb={1} sx={{opacity: voucherData?0.3:1}}>
				<ItemClaimFlowSubTitle text="2. Enter your security code" />

				<ItemClaimFlowSubTitleParagraph text="Copy and paste the security code you received via email in the form below." />
			</Box>

			<Box component="div" mb={3}>
				<TextField
					inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
					type="text"
					size="medium"
					variant="standard"
					id="security-code"
					sx={{
						mb: 1,
						width: "250px",
						color: "primary",
					}}
					onChange={(event) => setPincode(event?.target?.value)}
					value={pinCode}
					disabled={!!voucherData}
				/>
			</Box>

			{/* Double check ethereum address. */}
			<Box component="div" display="flex" flexDirection="column" >
				<Box component="div" display="flex" flexDirection="column" mb={1}>
					<ItemClaimFlowSubTitle text="3. Confirm address" />

					<ItemClaimFlowSubTitleParagraph
						text="Your NFT will be sent to the address below:"
						margin="0 0 10px 0"
					/>

					<Box
						component="div"
						sx={{ lineBreak: { xs: "anywhere", lg: "unset" } }}
					>
						<ItemClaimFlowSubTitleParagraph
							bold
							text={`${address ?? ""}`}
						/>
					</Box>
					{chain && differentNetwork && (
						<Alert
							icon={false}
							sx={{ my: 2, width: { xs: "100%" } }}
							severity="warning"
						>
							You are connected to the <b>{NETWORK[chain.id]} Network</b>.
							This NFT will be transferred to your wallet on the{" "}
							<b>{NETWORK[itemNetworkId]} Network</b>. Please make sure{" "}
							<Link
								href="https://metamask.zendesk.com/hc/en-us/articles/360043227612-How-to-add-a-custom-network-RPC"
								target="_blank"
								color="inherit"
							>
								you have access to this address on the {NETWORK[itemNetworkId]}{" "}
								Blockchain
							</Link>{" "}
							before claiming this NFT.
						</Alert>
					)}
				</Box>

				{/* Claim NFT. */}
				<Box component="div">

					{voucherData ? <MintVoucher advanceToNextStep={advanceToNextStep} voucherData={voucherData} /> : <Button
						variant="contained"
						disabled={
							redeemItemClaimStatus === QUERY_STATUS.loading ||
							!pinCode ||
							!address
						}
						onClick={onClaimNFT}
						loading={redeemItemClaimStatus === QUERY_STATUS.loading}
						text="Claim Your NFT"
						sx={{ width: "225px" }}
					/>}


					{redeemItemClaimStatus === QUERY_STATUS.error && (
						<Alert sx={{ mt: 2 }} severity="error" icon={false}>
							<ItemClaimFlowSubTitleParagraph
								text={`Failed to claim the NFT.${!redeemItemClaimStatusErrorMessage
									? " Please try again. If this problem persists, please refresh this page."
									: ""
									}`}
								margin={
									!!redeemItemClaimStatusErrorMessage ? "0 0 15px 0" : "0"
								}
							/>

							{redeemItemClaimStatusErrorMessage && (
								<ItemClaimFlowSubTitleParagraph
									text={`Reason: ${redeemItemClaimStatusErrorMessage}`}
								/>
							)}
						</Alert>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export interface ItemClaimFlowStep3ViewProps {
	pinFromUrl: string | undefined | null;
	useItemClaimResult: UseItemClaimResult;
	advanceToPreviousStep: () => void;
	advanceToNextStep: () => void;
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * Represents the 3rd step in the claim flow process.
 */
export default function ItemClaimFlowStep3View({
	pinFromUrl,
	useItemClaimResult,
	advanceToPreviousStep,
	advanceToNextStep,
	activeStep,
	setActiveStep,
}: ItemClaimFlowStep3ViewProps) {
	return (
		<>
			<Box component="div" display="flex" flexDirection="column">
				<Step3LeftHeader />

				<Step3LeftFooter
					pinFromUrl={pinFromUrl}
					{...useItemClaimResult}
					advanceToPreviousStep={advanceToPreviousStep}
					advanceToNextStep={advanceToNextStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>
			</Box>
		</>
	);
}
