import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
	WagmiConfig,
	createClient,
	configureChains,
	defaultChains,
} from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { infuraKey } from "./config";

const queryClient = new QueryClient();
const { chains, provider } = configureChains(defaultChains, [
	infuraProvider({ apiKey: infuraKey }),
	publicProvider(),
]);

const client = createClient({
	autoConnect: true,
	connectors: [new MetaMaskConnector({ chains })],
	provider,
});

const AppProviders = ({ children }: any) => (
	<QueryClientProvider client={queryClient}>
		<WagmiConfig client={client}>
			{children}
			<ReactQueryDevtools initialIsOpen />
		</WagmiConfig>
	</QueryClientProvider>
);

export default AppProviders;
