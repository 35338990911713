import { useEffect, useState } from "react";
import { useAccount, useConnect, useDisconnect, useNetwork, useContractWrite, usePrepareContractWrite, defaultChains } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";

export const useWallet = () => {
	const { connect, connectors } = useConnect({ connector: new MetaMaskConnector( {chains: defaultChains} )});
	const { disconnect } = useDisconnect();
	const { address } = useAccount();
	const { chain } = useNetwork();
	const [isMetamaskInstalled, SetMetamaskInstalled] = useState(false);

	function handleEthereum() {
		const { ethereum } = window;
		if (ethereum && ethereum.isMetaMask) {
			SetMetamaskInstalled(true);
		} else {
			SetMetamaskInstalled(false);
		}
	}

	useEffect(() => {
		if (window.ethereum) {
			handleEthereum();
		} else {
			window.addEventListener("ethereum#initialized", handleEthereum, {
				once: true,
			});
			setTimeout(handleEthereum, 3000);
		}
	});

	return {
		connect,
		connectors,
		disconnect,
		address,
		chain,
		isMetamaskInstalled,
		useContractWrite,
		usePrepareContractWrite
	};
};
