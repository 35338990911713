import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ItemClaimFlowSubTitleParagraph from "./SubTitleParagraph";
import { useWallet } from "../services/wallet";
import { voucher } from "../utils/types";
import Button from "./Button";
import ERC721VoucherMintABI from "../artifacts/ERC721VoucherMintABI.json"
import { useMemo, useState } from "react";
import { addGasMargin } from "../utils/helpers";

interface MintVoucherProps {
	voucherData: voucher;
	advanceToNextStep: () => void;
}

const MintVoucher = ({
	voucherData,
	advanceToNextStep,
}: MintVoucherProps) => {
	const { useContractWrite, usePrepareContractWrite } = useWallet();
	const [txError, setTxError] = useState(false);

	const voucherArgs = {
		quantity: voucherData.quantity,
		nonce: voucherData.nonce,
		expiry: Math.floor(new Date(voucherData.expiry).getTime() / 1000),
		price: Number(voucherData.price),
		tokenId: Number(voucherData.token_id),
		currency: voucherData.currency
	};

	const { config, error, isError } = usePrepareContractWrite({
		addressOrName: voucherData.contract,
		contractInterface: ERC721VoucherMintABI,
		functionName: "publicMint",
		chainId: voucherData.network_id,
		args: [voucherArgs, voucherData.signature],
	});

	// add 20% to the estimated gas just to be safe
	const configWithPaddedGasLimit = useMemo(() => {
		if (!config || !config.request) {
			return config;
		} else {
			return {
				...config,
				request: {
					...config.request,
					gasLimit: addGasMargin(config.request.gasLimit),
				},
			};
		}
	}, [config]);

	const { write, writeAsync, isLoading, isSuccess } = useContractWrite(configWithPaddedGasLimit);

	const handleMint = async () => {
		if (writeAsync) {
			try {
				const tx = await writeAsync();
				const txReceipt = await tx.wait();
				if (txReceipt.status === 1) {
					advanceToNextStep();
				} else {
					setTxError(true);
				}
			} catch (err) {
				console.error(err)
				setTxError(true);
			}
		}
	}

	return (

		<>
			<Box component="div">
				<Button
					variant="contained"
					disabled={
						!write || isLoading
					}
					onClick={handleMint}
					loading={isSuccess}
					text="Mint Your NFT"
					sx={{ width: "225px" }}
				/>
			</Box>
			{isSuccess && <Alert sx={{ mt: 2 }} severity="info" icon={false}>Your NFT is being minted.. Please allow 1-2 minutes while the blockchain is confirming the transaction.</Alert>}

			{isError && (
				<Alert sx={{ mt: 2 }} severity="error" icon={false}>
					<ItemClaimFlowSubTitleParagraph
						text={`Failed to prepare your voucher due to ${error}. Please contact support.`}
						margin={
							"0 0 15px 0"
						}
					/>
				</Alert>
			)}

			{txError && (
				<Alert sx={{ mt: 2 }} severity="error" icon={false}>
					<ItemClaimFlowSubTitleParagraph
						text={`Your transaction failed due to some reason. Please refresh the page and try again.`}
						margin={
							"0 0 15px 0"
						}
					/>
				</Alert>
			)}
		</>


	)
};


export default MintVoucher;