import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Page from "../components/Page";

export const LandingPage = () => {
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 900px)" });
	const [token, setToken] = useState<string>("");
	const navigate = useNavigate();

	return (
		<Page title="Home">
			<Layout>
				<Box
					component="div"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{
						position: "relative",
						minHeight: "calc(100vh - 310px)",
						height: isMobileOrTablet ? "100%" : null,
					}}
					width="100%"
				>
					<Box
						component="div"
						sx={{
							width: { xs: "100%", lg: "600px" },
						}}
					>
						<Typography
							variant={isMobileOrTablet ? "h4" : "h2"}
							color="primary"
							gutterBottom
							fontWeight={600}
						>
							Claim Your NFT
						</Typography>
						<Typography variant="body1">
							If you have a claim token, please input it below.
						</Typography>
						<Box
							component="div"
							display="flex"
							flexDirection={isMobileOrTablet ? "column" : "row"}
							alignItems={isMobileOrTablet ? "center" : "flex-end"}
							sx={{ my: 3 }}
						>
							<TextField
								type="text"
								id="token"
								size="medium"
								variant="standard"
								sx={{
									width: 1,
									color: "primary",
									mr: isMobileOrTablet ? 0 : 3,
									mb: isMobileOrTablet ? 3 : 0,
								}}
								onChange={(event) => setToken(event?.target?.value.trim())}
								value={token}
							/>
							<Button
								disabled={!token}
								variant="outlined"
								onClick={() => navigate(`/claim/${token}`)}
								text="Submit"
								sx={{ width: "200px" }}
							/>
						</Box>
					</Box>
				</Box>
			</Layout>
		</Page>
	);
};
