import { Container } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";

interface LayoutProps {
	children: any;
}

const Layout = ({ children }: LayoutProps) => {
	return (
		<Container>
			<Header />
			{children}
			<Footer />
		</Container>
	);
};

export default Layout;
