import { Typography } from "@mui/material";

export interface ItemClaimFlowSubTitleProps {
	text: string;
	margin?: string;
	textTransform?: string;
}

/**
 * Encapsulates styling associated with a subtitle in the claim flow process.
 */
export default function ItemClaimFlowSubTitle({
	text,
	margin,
	textTransform,
}: ItemClaimFlowSubTitleProps) {
	return (
		<Typography
			align="left"
			variant="body1"
			sx={{
				textTransform: textTransform ?? "uppercase",
				margin: margin ?? "0 0 10px 0",
				lineHeight: 1.5,
			}}
		>
			{text}
		</Typography>
	);
}
