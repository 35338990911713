import { Box, Link, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Layout from "../components/Layout";
import Page from "../components/Page";

interface FAQProps {
	question: string;
	answer: any;
}

const FAQ = ({ question, answer }: FAQProps) => {
	return (
		<Box sx={{ my: 2 }}>
			<Typography
				variant="caption"
				component="div"
				fontWeight={600}
				fontSize={14}
			>
				{question}
			</Typography>
			<Typography variant="caption" component="div" fontSize={14}>
				{answer}
			</Typography>
		</Box>
	);
};

export const FAQPage = () => {
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 900px)" });

	return (
		<Page title="Home">
			<Layout>
				<Box
					component="div"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{
						position: "relative",
						minHeight: "calc(100vh - 310px)",
						height: isMobileOrTablet ? "100%" : null,
					}}
					width="100%"
				>
					<Box
						component="div"
						display="flex"
						flexDirection="column"
						sx={{
							width: { xs: "100%", lg: "600px" },
							my: 3,
						}}
					>
						<Typography
							variant={isMobileOrTablet ? "h4" : "h2"}
							color="primary"
							gutterBottom
							fontWeight={600}
						>
							FAQ
						</Typography>
						<FAQ
							question="I have successfully claimed the NFT but can't see it in MetaMask. Where is it?"
							answer={
								<>
									<p>
										The MetaMask browser extension currently does not support
										viewing NFT natively in the UI. However, you should be able
										to see it in the MetaMask mobile app.
									</p>
									<ol>
										<li>
											Open the MetaMask mobile app, make sure you are viewing
											the account the NFT was sent to.
										</li>
										<li>Select NFTs, then Import NFTs</li>
										<li>Input the Token Address and Token Id as suggested.</li>
									</ol>
									<p>
										You should also be able to see it when connecting your
										wallet in your favorite marketplaces such as OpenSea or
										LooksRare. Be sure to check under the 'Hidden' tab too.
									</p>{" "}
									<p>
										If you still cannot find it, please reach out to support.
									</p>
								</>
							}
						/>
						<FAQ
							question="I have MetaMask installed on mobile but unable to connect to it."
							answer="The best way to claim the NFT on mobile is to use the MetaMask mobile in-app browser. Copy and paste the link we sent you in the claim email and open it in the MetaMask in-app browser. You should be able to continue the process."
						/>
						<FAQ
							question="I have connected MetaMask but want to switch account/network. How can I do that?"
							answer="Simply tap on the browser extension icon or make the switch in the MetaMask mobile app. Our app would automatically detect that."
						/>
						<FAQ
							question="I have claimed the NFT with one address and want to transfer to another?"
							answer={
								<>
									You will have to initiate a transfer through the blockchain.
									Kindly refer to
									{
										<Link
											underline="none"
											target="_blank"
											href="https://metamask.zendesk.com/hc/en-us/articles/360058961911-How-do-I-send-receive-or-cash-out-an-NFT-"
										>
											{" "}
											this official guide
										</Link>
									}{" "}
									for sending and receiving NFTs through MetaMask.
								</>
							}
						/>
						<FAQ
							question="Any other questions? Please reach out to support."
							answer=""
						/>
					</Box>
				</Box>
			</Layout>
		</Page>
	);
};
