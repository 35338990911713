import Typography from "@mui/material/Typography";

export interface ItemClaimFlowTitleParagraphProps {
	text: string;
}

/**
 * Encapsulates styling associated with a title paragraph in the claim flow process.
 */
export default function ItemClaimFlowTitleParagraph({
	text,
}: ItemClaimFlowTitleParagraphProps) {
	return (
		<>
			<Typography variant="body1" align="left">
				{text}
			</Typography>
		</>
	);
}
