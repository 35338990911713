import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
	palette: {
		primary: {
			main: "#2E4AA3",
			light: "#D5DDF8",
			dark: "#809AEB",
		},
		error: {
			main: red.A400,
		},
	},
});

export default theme;
