export const CLAIMFLOW_STEP = {
	CONNECT_METAMASK: "Connect MetaMask",
	VERIFY_IDENTITY_AND_CLAIM: "Verify Identity And Claim",
	SUCCESSFULLY_CLAIMED: "Successfully Claimed NFT",
};

export const METAMASK_PROVIDER_NAME = "MetaMask";
export const INJECTED_PROVIDER_NAME = "injected";

export const QUERY_STATUS = {
	loading: "loading",
	success: "success",
	error: "error",
	idle: "idle",
};

export const NETWORK = {
	1: "Ethereum",
	4: "Rinkeby",
	137: "Polygon",
};
