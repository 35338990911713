import { Box } from "@mui/material";
import { useWallet } from "../services/wallet";
import { ReactComponent as MetaMaskFoxSvg } from "../img/metamask-fox.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { truncateString } from "../utils/helpers";
import Button from "./Button";

const WalletButton: React.FC<any> = () => {
	const { address, chain, disconnect, connect} = useWallet();
	const isConnected = address && chain;

	return (
		<Box component="div">
			<Button
				variant={isConnected ? "outlined" : "contained"}
				startIcon={<MetaMaskFoxSvg style={{ height: "25px", width: "25px" }} />}
				endIcon={
					address ? (
						<CheckCircleIcon style={{ height: "20px", width: "20px" }} />
					) : null
				}
				onClick={
					isConnected ? () => disconnect() : () => connect()
				}
				text={
					address
						? `${truncateString(address!, 20, "...")}`
						: "Connect MetaMask"
				}
			/>
		</Box>
	);
};

export default WalletButton;
