import Box from "@mui/material/Box";
import ItemClaimFlowTitle from "../Title";
import ItemClaimFlowTitleParagraph from "../TitleParagraph";
import { openSeaUrl } from "../../config";
import { CLAIMFLOW_STEP } from "../../utils/constants";
import { Item } from "../../utils/types";
import Button from "../Button";

const Step4LeftHeader = () => {
	return (
		<Box component="div" display="flex" flexDirection="column" sx={{ mb: 1 }}>
			<ItemClaimFlowTitle text={CLAIMFLOW_STEP.SUCCESSFULLY_CLAIMED} />

			<ItemClaimFlowTitleParagraph text="Congratulations on securing your very own NFT. After a short delay, it will be transferred to your MetaMask wallet." />
		</Box>
	);
};

const Step4LeftFooter = ({ item }: ItemClaimFlowStep4ViewProps) => {
	const itemNetworkId = item.token_contract.network_id;
	const OpenSeaSupported = itemNetworkId in [1, 137];

	const onViewOnOpenSeaClick = () => {
		if ((!openSeaUrl && !item.token_contract) || !OpenSeaSupported) return;

		if (openSeaUrl) {
			window.open(openSeaUrl);
		} else if (itemNetworkId === 1) {
			window.open(
				!!item.token_id
					? `https://opensea.io/assets/ethereum/${item.token_contract.address}/${item.token_id}`
					: `https://opensea.io/assets/ethereum/${item.token_contract.address}`
			);
		} else if (itemNetworkId === 137) {
			window.open(
				!!item.token_id
					? `https://opensea.io/assets/matic/${item.token_contract.address}/${item.token_id}`
					: `https://opensea.io/assets/matic/${item.token_contract.address}`
			);
		}
	};

	return (
		<Box component="div" marginTop={2}>
			<Button
				onClick={onViewOnOpenSeaClick}
				disabled={(!openSeaUrl && !item.token_contract) || !OpenSeaSupported}
				variant="outlined"
				text="View on OpenSea"
			/>
		</Box>
	);
};

export interface ItemClaimFlowStep4ViewProps {
	item: Item;
}

/**
 * Represents the 5th step in the claim flow process.
 */
export default function ItemClaimFlowStep5View({
	item,
}: ItemClaimFlowStep4ViewProps) {
	return (
		<>
			<Box component="div" display="flex" flexDirection="column">
				<Step4LeftHeader />

				<Step4LeftFooter item={item} />
			</Box>
		</>
	);
}
