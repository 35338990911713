import { Box, Divider, Link, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 999px)" });

	return (
		<>
			<Box display="flex" justifyContent="space-between" sx={{ mt: 5 }}>
				<Link href="/faq" underline="none">
					<Typography
						variant="body1"
						align="left"
						gutterBottom
						fontWeight={600}
						component="div"
						sx={{ maxWidth: isMobileOrTablet ? 0.5 : 1 }}
					>
						FREQUENTLY ASKED QUESTIONS
					</Typography>
				</Link>
				<Link
					href="https://consensysnft.zendesk.com/hc/en-us"
					underline="none"
					target="_blank"
				>
					<Typography
						variant="body1"
						align="right"
						gutterBottom
						fontWeight={600}
						component="div"
					>
						CONTACT SUPPORT
					</Typography>
				</Link>
			</Box>
			<Divider color="#727272" sx={{ height: "1px", mt: 3 }} />
			<Typography
				variant="caption"
				sx={{ fontSize: isMobileOrTablet ? "12px" : "14px" }}
			>
				©CONSENSYS NFT ALL RIGHTS RESERVED
			</Typography>
		</>
	);
};

export default Footer;
