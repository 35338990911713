import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Typography,
	Grid,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { NETWORK } from "../utils/constants";
import { ItemClaim } from "../utils/types";
import EthAddressExplorer from "./EthAddressExplorer";
import placeholderImg from "../img/placeholderImg.png";

interface AttributeProps {
	name: string;
	value: any;
}

const Attribute = ({ name, value }: AttributeProps) => {
	return (
		<Grid container sx={{ px: 3 }} display="flex" alignItems="flex-end">
			<Grid item xs={6}>
				<Typography variant="caption">{name}</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="caption" fontWeight={600}>
					{value}
				</Typography>
			</Grid>
		</Grid>
	);
};

export interface ItemDetailsProps {
	itemClaim: ItemClaim;
}

export default function ItemDetails({ itemClaim }: ItemDetailsProps) {
	const item = itemClaim.item;
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 900px)" });

	return (
		<Box
			component="div"
			display="flex"
			flexDirection="row"
			justifyContent="center"
			sx={{ my: isMobileOrTablet ? 0 : 5 }}
		>
			<Card
				sx={{
					width: { xs: "100%", lg: "600px" },
					display: "flex",
					flexDirection: isMobileOrTablet ? "column" : "row",
					alignItems: "center",
					borderRadius: 0,
				}}
				elevation={0}
			>
				<CardMedia
					component="img"
					image={
						item.media.image?.full ??
						item.media.image?.original ??
						placeholderImg
					}
					sx={{
						maxWidth: isMobileOrTablet ? 1 : 0.5,
						objectFit: "contain",
						border: "1px solid black",
						p: 2,
					}}
				/>
				<CardContent>
					<Typography gutterBottom variant="h3" component="div" align="center">
						{item.attributes.title}
					</Typography>
					<Attribute
						name="Network"
						value={NETWORK[item.token_contract.network_id]}
					/>
					<Attribute name="Token Id" value={item.token_id} />
					<Attribute
						name="Token Address"
						value={
							<EthAddressExplorer
								address={item.token_contract.address}
								networkId={item.token_contract.network_id}
							/>
						}
					/>
				</CardContent>
			</Card>
		</Box>
	);
}
