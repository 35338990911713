import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Typography } from "@mui/material";
import ItemClaimFlowTitleParagraph from "../TitleParagraph";
import { CLAIMFLOW_STEP } from "../../utils/constants";
import ItemClaimFlowTitle from "../Title";
import WalletButton from "../WalletButton";
import { useWallet } from "../../services/wallet";
import Button from "../Button";
import { useMediaQuery } from "react-responsive";

const Step2ViewLeftHeader = () => {
	return (
		<Box component="div" display="flex" flexDirection="column" sx={{ mb: 3 }}>
			<ItemClaimFlowTitle text={CLAIMFLOW_STEP.CONNECT_METAMASK} />
			<ItemClaimFlowTitleParagraph text="Create or connect your MetaMask wallet to begin the process of claiming your NFT." />
		</Box>
	);
};

const Step2ViewLeftFooter = ({ isMetamaskInstalled }) => {
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 900px)" });
	const path = window.location.href
		.replace("http://", "")
		.replace("https://", "");
	console.log(`https://metamask.app.link/dapp/${path}`);
	return (
		<Box
			component="div"
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			{isMetamaskInstalled ? (
				<WalletButton />
			) : isMobileOrTablet ? (
				<>
					<Typography variant="caption" sx={{ pr: 2 }}>
						On mobile? Copy and paste the following link to MetaMask Mobile's
						in-app browser:
					</Typography>

					<Typography
						variant="caption"
						component="textarea"
						defaultValue={window.location.href}
						readOnly
						rows={3}
						sx={{
							mt: 2,
							mb: 5,
							pr: 2,
							width: 1,
						}}
					></Typography>

					<Button
						variant="contained"
						startIcon={<OpenInNewIcon />}
						onClick={() =>
							window.open(`https://metamask.app.link/dapp/${path}`)
						}
						text="Open MetaMask Mobile"
					/>
				</>
			) : (
				<Button
					variant="contained"
					startIcon={<OpenInNewIcon />}
					onClick={() => window.open("https://metamask.io/")}
					text="Get MetaMask"
				/>
			)}

			<Typography variant="caption" sx={{ my: 5, pr: 2 }}>
				Please use Google Chrome or Firefox desktop browsers for best results.
				Safari is not supported by MetaMask.
			</Typography>
		</Box>
	);
};

export interface ItemClaimFlowStep2ViewProps {
	advanceToNextStep: () => void;
}

/**
 * Represents the 2nd step in the claim flow process.
 */
export default function ItemClaimFlowStep2View({
	advanceToNextStep,
}: ItemClaimFlowStep2ViewProps) {
	// We'll need this to connect to MetaMask & to know what the current account is.
	const { isMetamaskInstalled, address } = useWallet();

	// MOBILE: If we're connected with any account, we can advance to the next step.
	if (address) {
		advanceToNextStep();
		// DESKTOP: If we're connected to an account and metamask is installed, we can advance to the next step.
	} else if (isMetamaskInstalled && address) {
		advanceToNextStep();
	}

	return (
		<Box component="div" display="flex" flexDirection="column">
			<Step2ViewLeftHeader />

			<Step2ViewLeftFooter isMetamaskInstalled={isMetamaskInstalled} />
		</Box>
	);
}
