import { Box, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import Confetti from "react-confetti";

import { CLAIMFLOW_STEP, QUERY_STATUS } from "../utils/constants";
import { useItemClaim } from "../services/itemClaimService";
import Page from "../components/Page";
import Layout from "../components/Layout";
import ItemClaimFlowStep2View from "../components/ClaimFlow/Step2View";
import ItemClaimFlowStep3View from "../components/ClaimFlow/Step3View";
import ItemClaimFlowStepper from "../components/ClaimFlow/Stepper";
import ItemClaimFlowStep4View from "../components/ClaimFlow/Step4View";
import theme from "../theme";
import ItemDetails from "../components/ItemDetails";

const ClaimsPage = () => {
	const { claimToken = "", pinCode = "" } = useParams();
	const useItemClaimResult = useItemClaim(claimToken);
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 900px)" });
	const [currentStep, setCurrentStep] = useState<string>(
		CLAIMFLOW_STEP.CONNECT_METAMASK
	);
	const [activeStep, setActiveStep] = useState<number>(
		Object.values(CLAIMFLOW_STEP).indexOf(currentStep)
	);

	const scrollHeight = Math.max(
		document.body.scrollHeight,
		document.body.offsetHeight,
		document.body.clientHeight
	);

	return (
		<Page title="Claim Flow">
			<Layout>
				<Box
					component="div"
					display="flex"
					alignItems={
						useItemClaimResult.status === QUERY_STATUS.success
							? "flex-start"
							: "center"
					}
					justifyContent={
						useItemClaimResult.status === QUERY_STATUS.success
							? "flex-start"
							: "center"
					}
					sx={{
						position: "relative",
						minHeight: "calc(100vh - 310px)",
						height: isMobileOrTablet ? "100%" : null,
					}}
					width="100%"
				>
					{useItemClaimResult.status === QUERY_STATUS.loading && (
						<CircularProgress sx={{ color: "black !important" }} />
					)}

					{useItemClaimResult.status === QUERY_STATUS.error && (
						<Box
							component="div"
							display="flex"
							flexDirection="column"
							sx={{
								width: { xs: "100%", lg: "600px" },
							}}
						>
							<Typography
								variant={isMobileOrTablet ? "h4" : "h2"}
								color="primary"
								gutterBottom
								fontWeight={600}
							>
								Claim not active..
							</Typography>
							<Typography variant="body1">
								You might have already claimed this NFT. If you believe there is
								an error, please contact support.
							</Typography>
						</Box>
					)}

					{useItemClaimResult.status === QUERY_STATUS.success &&
						useItemClaimResult.itemClaim && (
							<Box component="div" width="100%" marginTop="40px">
								<Typography
									variant={isMobileOrTablet ? "h4" : "h2"}
									color="primary"
									gutterBottom
									fontWeight={600}
								>
									Claim Your NFT
								</Typography>
								<ItemDetails itemClaim={useItemClaimResult.itemClaim} />
								<Box
									component="div"
									display="flex"
									sx={{
										justifyContent: { xs: "center", lg: "flex-start" },
										flexDirection: { xs: "column", lg: "row" },
										py: "1.5em",
									}}
								>
									<Box
										component="div"
										width={isMobileOrTablet ? "100%" : "525px"}
										sx={{ mr: isMobileOrTablet ? 0 : 5 }}
									>
										{currentStep === CLAIMFLOW_STEP.CONNECT_METAMASK && (
											<ItemClaimFlowStep2View
												advanceToNextStep={() => {
													setActiveStep(activeStep + 1);
													setCurrentStep(
														CLAIMFLOW_STEP.VERIFY_IDENTITY_AND_CLAIM
													);
												}}
											/>
										)}

									{currentStep ===
										CLAIMFLOW_STEP.VERIFY_IDENTITY_AND_CLAIM && (
											<ItemClaimFlowStep3View
												pinFromUrl={pinCode}
												useItemClaimResult={useItemClaimResult}
												advanceToPreviousStep={() => {
													setCurrentStep(CLAIMFLOW_STEP.CONNECT_METAMASK);
													setActiveStep(activeStep - 1);
												}}
												advanceToNextStep={() => {
													setCurrentStep(CLAIMFLOW_STEP.SUCCESSFULLY_CLAIMED);
													setActiveStep(activeStep + 2);
												}}
											
												activeStep={activeStep}
												setActiveStep={setActiveStep}
											/>
										)}
										{currentStep === CLAIMFLOW_STEP.SUCCESSFULLY_CLAIMED && (
											<ItemClaimFlowStep4View
												item={useItemClaimResult.itemClaim.item}
											/>
										)}
									</Box>

									<Box
										component="div"
										sx={{
											pt: isMobileOrTablet ? 5 : 0,
											width: isMobileOrTablet ? "100%" : "550px",
										}}
									>
										<ItemClaimFlowStepper
											fontColor={theme.palette.primary.main}
											activeStepLabelColor={theme.palette.primary.main}
											activeStepLabelFontColor="white"
											activeStepLabelBorderColor={theme.palette.primary.main}
											completeStepLabelColor={theme.palette.success.main}
											completeStepLabelFontColor="white"
											completeStepLabelBorderColor={theme.palette.success.main}
											todoStepLabelColor="white"
											todoStepLabelFontColor="black"
											todoStepLabelBorderColor="black"
											activeStep={activeStep}
											title={
												currentStep === CLAIMFLOW_STEP.SUCCESSFULLY_CLAIMED
													? "All Done!"
													: "What's Next"
											}
											titleFontVariant="h5"
											connectorColor="black"
										/>
									</Box>
								</Box>
							</Box>
						)}
				</Box>
			</Layout>
			{currentStep === CLAIMFLOW_STEP.SUCCESSFULLY_CLAIMED && (
				<Confetti height={scrollHeight} numberOfPieces={50} gravity={0.04} />
			)}
		</Page>
	);
};

export default ClaimsPage;
