import { Box, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Layout from "../components/Layout";
import Page from "../components/Page";

export const NotFound = () => {
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 900px)" });

	return (
		<Page title="Not Found">
			<Layout>
				<Box
					component="div"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{
						position: "relative",
						minHeight: "calc(100vh - 310px)",
						height: isMobileOrTablet ? "100%" : null,
					}}
					width="100%"
				>
					<Box
						component="div"
						display="flex"
						flexDirection="column"
						sx={{
							width: { xs: "100%", lg: "600px" },
							my: 3,
						}}
					>
						<Typography
							variant={isMobileOrTablet ? "h4" : "h2"}
							color="primary"
							gutterBottom
							fontWeight={600}
						>
							Oops!
						</Typography>
						<Typography variant="body1">
							We cannot locate the page you are looking for. Please double check
							your url or contact support.
						</Typography>
					</Box>
				</Box>
			</Layout>
		</Page>
	);
};
