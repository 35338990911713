import { Box, Link } from "@mui/material";
import { useWallet } from "../services/wallet";
import logoImg from "../img/Consensys_NFT_logo_filled.svg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WalletButton from "./WalletButton";
import { useMediaQuery } from "react-responsive";
import Button from "./Button";

const Header = () => {
	const { isMetamaskInstalled } = useWallet();
	const isMobileOrTablet = useMediaQuery({ query: "(max-width: 900px)" });

	return (
		<Box
			display="flex"
			alignItems="center"
			flexDirection={isMobileOrTablet ? "column" : "row"}
			justifyContent={isMobileOrTablet ? "center" : "space-between"}
		>
			<Box component="div" display="flex">
				<Box>
					<Link href="/">
						<img
							src={logoImg}
							title="logo"
							style={{
								width: "233px",
								margin: "10px 0",
							}}
							alt="logo"
						/>
					</Link>
				</Box>
			</Box>

			<Box component="div">
				{isMetamaskInstalled ? (
					<WalletButton />
				) : isMobileOrTablet ? (
					<></>
				) : (
					<Button
						variant="contained"
						startIcon={<OpenInNewIcon />}
						onClick={() => window.open("https://metamask.io/")}
						text="Get Metamask"
					/>
				)}
			</Box>
		</Box>
	);
};

export default Header;
